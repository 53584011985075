import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ProductAttributesContainer as SourceProductAttributesContainer } from 'SourceComponent/ProductAttributes/ProductAttributes.container';
/** @namespace Pwa/Component/ProductAttributes/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});
/** @namespace Pwa/Component/ProductAttributes/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/ProductAttributes/Container/ProductAttributesContainer */
export class ProductAttributesContainer extends SourceProductAttributesContainer {
    static propTypes = {
        ...SourceProductAttributesContainer.propTypes,
        isProductDetails: PropTypes.bool,
    };

    componentDidMount() {
        const { isProductDetails } = this.props;

        if (!isProductDetails) {
            return null;
        }
        this.detailsHeaderClickListener();
    }

    componentWillUnmount() {
        const { isProductDetails } = this.props;

        if (!isProductDetails) {
            return null;
        }

        this.detailsHeaderClickListener('remove');
    }

    detailsHeaderClickListener(type = 'add') {
        const { isProductDetails } = this.props;

        if (!isProductDetails) {
            return null;
        }
        if (type === 'remove') {
            document.removeEventListener('click', this.handleDetailsHeaderClick.bind(this));
            return;
        }

        document.addEventListener('click', this.handleDetailsHeaderClick.bind(this));
    }

    handleDetailsHeaderClick(e) {
        const {
            device: { isMobile },
        } = this.props;
        const { target } = e;
        const productDetails = document.querySelector('.ProductPage-Wrapper .ProductAttributes-Content');

        const targetClass = isMobile ? 'ProductAttributes_ContentHeadingDetails' : 'ProductAttributes-ShowAllButton';

        if (target?.classList.contains(targetClass)) {
            productDetails.classList.toggle('isActive');
        }
    }

    containerProps() {
        const { isProductDetails, device, isSearchable, isLoading } = this.props;
        return {
            ...super.containerProps(),
            isLoading,
            isSearchable,
            isProductDetails,
            device,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductAttributesContainer);
