/* eslint-disable array-callback-return */
/* eslint-disable fp/no-let */
import { Fragment } from 'react';

import Link from 'Component/Link';
import ProductAttributeValue from 'Component/ProductAttributeValue';
import TextPlaceholder from 'Component/TextPlaceholder';
import { ProductAttributes as SourceProductAttributes } from 'SourceComponent/ProductAttributes/ProductAttributes.component';
import { replaceEmptySpaces } from 'Util/Attributes/Attributes';

import {
    BRAND,
    DEFAULT_SEARCHING_URL,
    DETAILS_VISIBLE_ATTRIBUTES_AMOUNT,
    EMPTY_SPACE_REPLACEMENT_SIGN,
    SERIES,
    TEXT_TYPE,
    TEXTAREA_TYPE,
    WEIGHT_TYPE,
} from './ProductAttributes.config';

import './ProductAttributes.override.style';

/** @namespace Pwa/Component/ProductAttributes/Component/ProductAttributes */
export class ProductAttributes extends SourceProductAttributes {
    __construct(props) {
        super.__construct(props);

        this.state = {
            isOpen: false,
        };
    }

    handleShowAllAttributesButtonClick() {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen });
    }

    renderGroups() {
        const { attributesWithValues } = this.props;

        const groups = Object.values(attributesWithValues).map((attribute) => ({
            attribute_group_id: attribute.attribute_group_id,
            attribute_group_name: attribute.attribute_group_name,
        }));

        const uniqueGroups = groups.filter(
            (group, index, array) => index === array.findIndex((g) => g.attribute_group_id === group.attribute_group_id)
        );

        return (
            <dl block="ProductAttributes" elem="Attributes">
                {uniqueGroups.map((group) => this.renderAttributes(group.attribute_group_id))}
            </dl>
        );
    }

    renderAttributes(attribute_group_id) {
        const { attributesWithValues, isProductDetails } = this.props;

        if (!Object.keys(attributesWithValues).length) {
            return null;
        }

        const productDetailsAttributes = [];
        Object.values(attributesWithValues).map((attr, i) => {
            if (i < DETAILS_VISIBLE_ATTRIBUTES_AMOUNT) {
                productDetailsAttributes.push(attr);
            }
        });

        const filteredProductDetailsAttributes = productDetailsAttributes.filter(
            (attribute) => attribute.attribute_group_id === attribute_group_id
        );

        const filteredAttributesWithValues = Object.values(attributesWithValues).filter(
            (attribute) => attribute.attribute_group_id === attribute_group_id
        );

        if (!filteredProductDetailsAttributes.length || !filteredAttributesWithValues.length) {
            return null;
        }

        if (isProductDetails) {
            return filteredProductDetailsAttributes.map(this.renderAttribute.bind(this));
        }

        return filteredAttributesWithValues.map(this.renderAttribute.bind(this));
    }

    renderAttribute(attribute) {
        const { isProductDetails, isSearchable, attributesWithValues } = this.props;
        const { searchable_pdp, attribute_code, attribute_options, attribute_value, attribute_type } = attribute;
        let attributeURL = DEFAULT_SEARCHING_URL;
        const attributeParam = attribute_options[attribute_value]?.label;

        if (attribute_type === WEIGHT_TYPE || attribute_type === TEXT_TYPE || attribute_type === TEXTAREA_TYPE) {
            attributeURL += `${attribute_code}:${replaceEmptySpaces(attribute_value, EMPTY_SPACE_REPLACEMENT_SIGN)}`;
        }

        if (attributeParam) {
            attributeURL += `${attribute_code}:${replaceEmptySpaces(attributeParam, EMPTY_SPACE_REPLACEMENT_SIGN)}`;
        }

        if (attribute_code === SERIES) {
            Object.entries(attributesWithValues).map((attribute) => {
                if (attribute[1].attribute_code === BRAND) {
                    const brandParam = attribute[1].attribute_options[attribute[1].attribute_value]?.label;
                    attributeURL += `;${attribute[1].attribute_code}:${replaceEmptySpaces(
                        brandParam,
                        EMPTY_SPACE_REPLACEMENT_SIGN
                    )}`;
                }
            });
        }

        const formattedAttributeURL = attributeURL.replace('&', '%26');

        if (isSearchable && searchable_pdp) {
            return (
                <Fragment key={attribute.attribute_label}>
                    <dt block="ProductAttributes" elem="AttributeLabel">
                        {attribute.attribute_label}
                        {isProductDetails && ':'}
                    </dt>
                    <dd block="ProductAttributes" elem="ValueLabel">
                        <Link to={formattedAttributeURL}>
                            <ProductAttributeValue
                                key={attribute.attribute_label}
                                attribute={attribute}
                                isFormattedAsText
                            />
                        </Link>
                    </dd>
                </Fragment>
            );
        }

        return (
            <Fragment key={attribute.attribute_label}>
                <dt block="ProductAttributes" elem="AttributeLabel">
                    {attribute.attribute_label}
                    {isProductDetails && ':'}
                </dt>
                <dd block="ProductAttributes" elem="ValueLabel">
                    <ProductAttributeValue key={attribute.attribute_label} attribute={attribute} isFormattedAsText />
                </dd>
            </Fragment>
        );
    }

    renderPlaceholders() {
        return (
            <>
                <TextPlaceholder />
                <TextPlaceholder />
                <TextPlaceholder />
                <TextPlaceholder />
                <TextPlaceholder />
                <TextPlaceholder />
                <TextPlaceholder />
                <TextPlaceholder />
            </>
        );
    }

    renderContent() {
        const { areDetailsLoaded, isLoading, isProductDetails } = this.props;
        const heading =
            areDetailsLoaded && isProductDetails ? __('Product details') : areDetailsLoaded ? __('Details') : '';

        if (isLoading) {
            return (
                <div block="ProductAttributes" elem="Content" mods={{ isLoading: true }}>
                    {this.renderPlaceholders()}
                </div>
            );
        }

        return (
            <div block="ProductAttributes" elem="Content">
                <h2
                    block="ProductAttributes"
                    elem="ContentHeading"
                    mix={
                        isProductDetails && {
                            block: 'ProductAttributes',
                            mods: { ContentHeadingDetails: isProductDetails },
                        }
                    }
                >
                    {heading}
                </h2>
                {this.renderGroups()}
            </div>
        );
    }
}

export default ProductAttributes;
